import React from "react";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";

import img_gatsby from "assets/images/caim.png";

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Helmet>
        <title>Caim</title>
      </Helmet>
      <Container>
        <p className="cover">
          <img src={img_gatsby} alt="Caim" />
        </p>
        {/* <h1 className="heading">Welcome</h1> */}
        <p className="heading">Caim - Meaning 'Sanctuary' or 'Protection' </p><br />
        <p className="heading">Origin - Gaelic</p><br />
        <p className="heading">(Noun)
          An invisible circle of protection drawn around the body with the hand, that reminds you that you are safe &amp; loved even in the darkest of times. </p><br />
          <p className="heading">The 'Caim' was a profound circling prayer/chant used by ancient Celts &amp; others over the Millennia.</p>
      </Container>
    </Layout>
  );
};

export default IndexPage;
